$font-family-serif: 'Montserrat', sans-serif;
$font-family-sans-serif: 'Raleway', sans-serif;

$main-color: #212529;
$secondary-color: #ee93ab;
$gray: #a5a5a5;

body {
  font-family: $font-family-serif !important;
  margin: 0;

  	h1 {
		font-size: 42px;
		line-height: 1.3;
	}

	h2 {
		font-size: 24px;
		line-height: 1.3;
	}

	h3 {
		font-size: 22px;
		line-height: 1.3;
	}

	h4 {
		font-size: 20px;
		line-height: 1.3;
		margin-bottom: 8px;
	    font-weight: 500;
	}

	h5 {
		font-size: 18px;
		line-height: 1.3;
	}

	h6 {
		font-size: 16px;
		line-height: 1.3;
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	h1,h2,h3,h4,h5,h6 {
		font-family: $font-family-sans-serif;
		margin-bottom: 16px;
	}

	a {
		color: $gray;
		border-bottom: 1px dotted #999;
		&:hover {
			color:  #fff;
			opacity: .75;
			text-decoration: none;
		}
	}

	p {
		font-size: 16px;
		line-height: 24px;
		font-weight: 300;
		color: $gray;
	}

}

.btn {
	font-size: 16px;
    padding: 10px 20px;
    letter-spacing: 1px;
	&.btn-primary {
		background-color: $secondary-color;
		border: 2px solid $secondary-color;
	    &:hover, &:active {
	    	background-color: #fff !important;
	    	border: 2px solid $secondary-color !important;
	    	color: $secondary-color !important;
	    }
	}

	&.btn-inverse {
		background-color: #fff;
    	border: 2px solid $secondary-color;
    	color: $secondary-color;
    	&:hover, &:active {
    		background-color: $secondary-color !important;
			border: 2px solid $secondary-color !important;
    	}
	}
}

@media (max-width: 991px) {
	body {
		h2 {
			margin-bottom: 8px;
		}
	}
}

@media (max-width: 479px) {
	body {
		p {
			font-size: 14px;
			line-height: 1.3;
		}
		h2 {
			font-size: 20px;
		}
	}
}
