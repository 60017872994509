.collapsible {
	margin-bottom: 16px;
	font-size: 16px;
	.header {
		border: 1px solid rgba($gray, .25);
		color: $secondary-color;
		padding: 16px;
		cursor: pointer;
	}
	.content {
		border-left: solid 1px #f2f2f2;
		border-right: solid 1px #f2f2f2;
		border-bottom: solid 1px #f2f2f2;
		border-radius: 0 0 5px 5px;
		padding: 16px;
	}
}