
:root {
  --color-accent: rgba($gray,0.3);
  --base-duration: 600ms;
  --base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);
}


#slider-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
}

.visuallyhidden { 
  clip: rect(1px, 1px, 1px, 1px);  
  height: 1px; 
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

// Slider controls

.slider-controls {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  
  .btn {
    --size: 2.5rem;
    align-items: center;
    height: var(--size);
    padding: 0;
    width: var(--size);
    margin: 0 4px;
    position: relative;
    
    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:active {
      transform: translateY(1px);
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
        
    &-previous i {
      transform: rotate(180deg) translate(0,0);
      top: 22%;
      left: 32%;
    }
  
    i.fa {
      font-size: 24px;
    }

  }
}


// Slider

.slider {
  --slide-size: 70vmin;
  --slide-height: 50vmin;
  --slide-margin: 4vmin;
  
  height: calc(var(--slide-height) + 80px);
  margin: 0 auto;
  position: relative;
  width: var(--slide-size);
}

.slider-wrapper {
  display: flex;
  margin: 0 calc(var(--slide-margin) * -1);
  position: absolute;
  transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
  padding: 0;
}


// Slide

.slide {
  color: $main-color;
  height: var(--slide-height);
  margin: 0 var(--slide-margin);
  opacity: 0.5;
  transition: 
    opacity calc(var(--base-duration) / 2) var(--base-ease),
    transform calc(var(--base-duration) / 2) var(--base-ease);
  width: var(--slide-size);
  z-index: 1;
  color: rgba($main-color, 0.8);
  list-style-type: none;
  
  &-previous,
  &-next {    
    &:hover {
      opacity: 0.5;
    }
  }
  
  &-previous {
    cursor: w-resize;
    
    &:hover {
      transform: translateX(2%);
    }
  }
  
  &-next {
    cursor: e-resize;
    
    &:hover {
      transform: translateX(-2%);
    }
  }
}

.slide-current {
  --x: 0;
  --y: 0;
  --d: 50;

  opacity: 1;
  pointer-events: auto;
  user-select: auto;
  
  @media (hover: hover) {
    &:hover .slide-content {
      transform: 
        scale(1.025)
        translate(
          calc(var(--x) / var(--d) * 1px),
          calc(var(--y) / var(--d) * 1px)
        );
    }    
  }
}

.slide-content {
  background-color: rgba(255,255,255,1);
  height: 100%;
  transition: transform calc(var(--base-duration) / 4) var(--base-ease);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 48px;
  text-align: center;
  position: relative;
    p {
        line-height: 28px;
        color: $main-color;
    }
  .slide-current & {
    animation: fade-in calc(var(--base-duration) / 2) var(--base-ease) forwards;
    visibility: visible;
    
    @media (hover: hover) {
      transform: 
        translate(
          calc(var(--x) / var(--d) * -1px),
          calc(var(--y) / var(--d) * -1px)
        );
    }
  }
}


// Animations

@keyframes fade-in {
  from { opacity: 0 }
  to   { opacity: 1 }
}


@media (max-width: 767px) {
  .slider {
    --slide-height: 55vmin;
  }
  .slide-content {
    padding: 16px 32px;
    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
}


@media (max-width: 575px) {
  .slider {
    --slide-height: 45vmin;
  }
  #testimonials h6.customer {
      bottom: 0;
  }
  .slide-content {
    padding: 16px;
    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

@media (max-width: 479px) {
  .slider {
    --slide-height: 85vmin;
     --slide-margin: 2vmin;
  }
  .slide-content {
    padding: 8px;
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
}