@import "./typography.scss";
@import "./slider.scss";
@import "./collapsible.scss";


.block {
    padding: 48px 0;
}

// GENERAL //
.title {
    text-align: center;
    margin-bottom: 32px;
}

.image-wrapper {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 0;
    padding-bottom: 100%;
}
.image-container {
    overflow: hidden;
    margin-bottom: 16px;
}


.site-wrapper {
    padding-top: 100px;
}

dt {
    dl {
        display: inline-block;
        width: 40%;
    }

    dd {
        display: inline-block;
        width: 60%;
    }
}

header {
    background: none;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    top: 0;

    a {
        transition: opacity 0.3s;
        color: #fff;
        opacity: .8;
        border-bottom: none;
        &:hover {
            color: #fff;
            opacity: 1;
            text-decoration: none;
        }
    }

    .site-header {
        padding: 0 6%;
        height: 50px;
        line-height: 50px;
        &.top {
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
            .header-left {
                a {
                    padding: 15px;
                    i.fa {
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
            }
            .header-right {
                i.fa {
                    margin-right: 8px;
                }
            }
        }
    }

    .header-left {
        margin-right: 35px;
        display: inline-block;
        a {
            border-bottom: none;
        }
        img {
            height: 50px;
            width: 50px;
        }
    }

    .header-right {
        display: inline-block;
        float: right;
        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            position: relative;
            li {
                display: inline-block;
                white-space: nowrap;
                position: relative;
                a {
                    font-family: $font-family-sans-serif;
                    padding: 10px 15px;
                    text-decoration: none;
                    font-weight: bold;
                    text-transform: uppercase;
                }
                ul {
                    display: none;
                }
                &:hover>ul {
                    display: block;
                }
            }
        }
    }

    &.light {
        a {
            color: $main-color;
            &:hover {
                color: $main-color;
            }
        }

        .site-header {
            &.top {
                border-bottom: 1px solid rgba($main-color, 0.4);
            }
        }
    } 
}


.frontpage-text {
	position: absolute;
	bottom: 64px;
	left: 0;
	z-index: 2;
	padding: 0 6%;
    color: #fff;

	h1 {
		text-align: left;
		margin: 0;
		font-weight: 700;
        font-size: 72px;
	}

	h5 {
		text-align: left;
		margin: 0 0 16px;
        font-size: 24px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
	}
}

.banner-wrapper {
    background: #303030 url("/images/IMG_20190123_0907502.jpg");
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    min-height: 100vh;
    display: flex;
    position: relative;
    margin-bottom: 48px;
    margin-top: -100px;
}

.overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	background-color: rgba(0,0,0,0.4);
}

// INFO //
#infos {
    h2 {
        margin-bottom: 16px;
    }
    .image-wrapper {
        background: #303030 url("/images/IMG_20191014_085415.jpg");
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        height: 0;
        padding-bottom: 100%;
    }
    ul {
        padding: 0;
    }
}


// PRODUCTS //

#products {
    text-align: center;
    a:hover {
        text-decoration: none;  
        .image-wrapper {
            transform: scale(1.1);
        }
    }
    a {
        color: $main-color;
        .image-wrapper {
            transition: .5s all ease;
        }
    }

}



/* SERVICE BLOCK */

.service {
    display: flex;
    flex-direction: row;
    i.fa {
        font-size: 50px;
        padding: 16px;
        color: $secondary-color;
    }
    .service-body p {
        margin-bottom: 0;
    }
}


/* TESTIMONIALS */

#testimonials {
    background-color: #f6f3f4;
    margin-top: 48px;
    .customer {
        text-align: right;
        color: $secondary-color;
        font-size: 14px;
        font-weight: bold;
        position: absolute;
        bottom: 32px;
        right: 32px;
        font-style: italic;
        text-transform: none;
    }
}


/* FOOTER */
footer {
    background-color: $main-color;
    .links {
        height: 100%;
        display: flex;
        align-items: center;
    }
    ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        li {
            margin:0 16px;
            display: inline-block;
        }
        .socials {
            i.fa {
                font-size: 20px;
                font-weight: bold;
                a & {
                    padding: 8px 16px;
                    border-bottom: none;
                }
            }
            a {
                padding: 8px;
                border-bottom: none;
                margin-right: 8px;
                &[href^="mailto:"] {
                    i.fa {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}



/* CONTACT FORM */
form {
    display: flex;
    flex-direction: column;

    label {
        margin-bottom: 8px;
        padding: 0 8px;
        color: #fff;
    }

    input, textarea {
        margin-bottom: 16px;
        background: transparent;
        border: none;
        border: 1px solid $gray;
        padding: 8px;
        font-size: 16px;
        &:focus {
            outline: none;
            background-color: rgba(255,255,255, 0.25);
        }
    }

    textarea {
        min-height: 160px;
    }

    .button-wrapper {
        text-align: right;
    }

    .dark {
        label {
            color: #fff;
        }
    }
}


@media (max-width: 991px) {
    .frontpage-text {
        bottom: 48px;
    }
    .service .service-body p {
        margin-bottom: 32px;
    }
    #services {
        padding-bottom: 0;
    }
    .banner-wrapper {
        margin-bottom: 48px;
    }
    #testimonials {
        margin-top: 48px;
    }

    footer ul li {
        margin-bottom: 16px;
       &:last-child {
            margin-bottom: 0;
        }
    }
}


@media (max-width: 767px) {
    .frontpage-text {
        bottom: 32px;
    }
    .banner-wrapper {
        margin-bottom: 32px;
    }
    .block {
        padding: 32px 0;
    }
    #testimonials {
        margin-top: 32px;
    }
    #products {
        padding-bottom: 0;
    }
    .category {
        margin-bottom: 32px;
    }
    #infos {
        h2 {
            text-align: center;
        }
    }
}


@media (max-width: 479px) {
    header .site-header.top .header-left {
        margin-right: 10px;
        a {
            padding: 15px 10px;
        }
    }
    .frontpage-text {
        bottom: 16px;
    }
    .banner-wrapper {
        margin-bottom: 16px;
    }
    .block {
        padding: 16px 0;
    }
    #testimonials {
        margin-top: 16px;
    }
    .service i.fa {
        font-size: 36px;
    }
}
